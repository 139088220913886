<template>
  <div class="side-palette">
    <transition name="sidebar-toggle" mode="out-in">
      <edit-type
        v-if="selectedCategory == null && searchText == ''"
        key="type"
        :layerTypes="layerTypeOptions"
        :layerNames="layerNames"
        @type-select="selectCategory($event)"
        @change-search="search($event)"
      />
      <!-- ブロックの検索結果 -->
      <edit-block-search
        v-else-if="searchText"
        key="search"
        :searchedBlocks="searchedBlocks"
        :searchText="searchText"
        @reset-select="resetSelect()"
      />
      <!-- 選択したブロックタイプのブロック一覧 -->
      <edit-block-list
        v-else
        key="block"
        :filteredBlocks="filteredBlocks"
        :selectedType="selectedCategory"
        @reset-select="resetSelect()"
      />
    </transition>
  </div>
</template>

<script>
import editType from '@/components/organisms/recipe-edit/palette/edit-type'
import editBlockList from '@/components/organisms/recipe-edit/palette/edit-block-list'
import editBlockSearch from '@/components/organisms/recipe-edit/palette/edit-block-search'

export default {
  components: {
    editType,
    editBlockList,
    editBlockSearch
  },
  data() {
    return {
      searchText: '',
      searchedBlocks: '',
      selectedCategory: null,
      fuseOptions: {
        includeScore: true,
        shouldSort: true,
        threshold: 0.3,
        keys: [
          {
            name: 'searchKey',
            weight: 1
          }
        ]
      }
    }
  },
  props: {
    recipe: Object,
    recipeLayers: Object,
    customblockList: Array
  },
  methods: {
    selectCategory(cat) {
      this.selectedCategory = cat
    },
    resetSelect() {
      this.selectedCategory = null
      this.searchText = ''
    },
    search($event) {
      this.searchText = $event.value
      this.searchedBlocks = Object.values($event.result).map((x) => x)
    }
  },
  computed: {
    layerTypeOptions() {
      return [
        'auto',
        'classification',
        'regression',
        'nlp',
        'deep',
        'finetuningDeep',
        'time',
        'clustering',
        'other',
        'hr',
        'optimization',
        'marketplace',
        'customblock',
        'rag'
      ]
    },
    layerCategories() {
      const category = { other: [] }
      const categorized = this.layerTypeOptions.filter((x) => x !== 'other')
      Object.values(this.recipeLayers).forEach((x) => {
        let type = x.type
        if (x.subscriptionNeeded) {
          type = 'marketplace'
        }
        if (categorized.includes(type)) {
          if (!category[type]) {
            category[type] = []
          }
          category[type].push(x)
        } else {
          category.other.push(x)
        }
      })
      return category
    },
    layerNames() {
      const nativeBlocks = Object.entries(this.recipeLayers).map(
        ([key, layer]) => {
          return {
            searchKey: this.$t('recipe.layerNames.' + key),
            type: layer.type,
            name: key
          }
        }
      )
      const customblocks = this.customblockList.map((item) => {
        return {
          searchKey: item.name,
          ...item
        }
      })
      return nativeBlocks.concat(customblocks)
    },
    filteredBlocks() {
      if (this.selectedCategory === 'customblock') {
        return this.customblockList
      }
      return this.layerCategories[this.selectedCategory]
    }
  }
}
</script>

<style lang="scss" scoped>
.side-palette {
  height: 100%;
}

.sidebar-toggle-enter-active,
.sidebar-toggle-leave-active {
  transition: transform $transition-base, opacity $transition-base;
  will-change: opacity, transform;
}
.sidebar-toggle-enter,
.sidebar-toggle-leave-to {
  opacity: 0;
  transform: translateX(-$space-medium);
  will-change: opacity, transform;
}
</style>
